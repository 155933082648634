export interface ShareHost {
  url: string;
  name: string;
  copied: boolean;
  render: () => HTMLElement;
}

export type ShareType =
  | "facebook"
  | "twitter"
  | "linkedin"
  | "telegram"
  | "whatsapp";

function openUrl(url: string, width = 640, height = 480) {
  const left = screen.width / 2 - width / 2;
  const top = screen.height * 0.3 - height / 2;
  const opt = `width=${width},height=${height},left=${left},top=${top},menubar=no,status=no,location=no`;

  window.open(url, "popup", opt);
}

export const supportsNative =
  !!window.navigator.share &&
  !!navigator.maxTouchPoints &&
  navigator.maxTouchPoints > 2;

export function share(host: ShareHost) {
  const { name, url } = host;

  window.navigator.share({ url });
}

export function shareSocial(shareType: ShareType) {
  return (host: ShareHost) => {
    const { name, url } = host;

    switch (shareType) {
      case "facebook":
        openUrl(
          `http://www.facebook.com/sharer/sharer.php?u=${host.url}`,
          555,
          400
        );
        break;
      case "twitter":
        openUrl(
          `https://twitter.com/intent/tweet?url=${url}&text=${name}`,
          650,
          300
        );
        break;
      case "linkedin":
        openUrl(
          `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${name}&summary=`
        );
        break;
      case "telegram":
        openUrl(`https://telegram.me/share/url?text=${name}&url=${url}`);
        break;
      case "whatsapp":
        window.open(`whatsapp://send?text=${name}%0A${url}`, "_top");
        break;
      default:
        throw TypeError("Invalid share type");
    }
  };
}

export function copy(host: ShareHost, event: Event) {
  const input = host.render().querySelector("input") as HTMLInputElement;

  event.preventDefault();
  input.focus();
  input.select();
  document.execCommand("copy");

  input.selectionStart = 0;
  input.selectionEnd = 0;

  host.copied = true;

  setTimeout(() => {
    host.copied = false;
  }, 2000);
}
